import { Image, Text, View, useWindowDimensions } from "react-native"

export const Home = () => {

    const { width } = useWindowDimensions();
    const cond = width <  1200;

    return (
        <View style={{ alignItems: "center", width: "100vw" }}>
            <View style={{ width: cond ? "100vw" : "calc(100vw - 80px)", maxWidth: 1700, paddingHorizontal: 40, flexDirection: cond ? "column" : "row", marginTop: 100 }}>
                <View style={{ width: cond ? "100%" : "40%" , height: cond ? "auto" : "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ fontSize: 52, fontWeight: "800", color: "rgb(18,72,251)" }}>Medalink</Text>
                    <Text style={{ fontWeight: "600" }}>Santé connectée, soins simplifiés</Text>
                    <Text style={{ paddingHorizontal: cond ? 15 : 40, marginTop: 20, textAlign: "justify"}}>Bienvenue dans l'univers de la santé connectée sur mesure ! Notre plateforme facilite la mise en relation entre patients et praticiens du secteur 3. Découvrez une approche novatrice grâce à notre chatbot intelligent qui vous guide vers le praticien idéal. Faites confiance à notre politique stricte de certification.</Text>
                    <Text style={{ paddingHorizontal: cond ? 15 : 40, marginTop: 20, textAlign: "center", fontWeight: "600" }}>Simplifiez votre parcours de santé avec nous, car votre bien être est notre priorité absolue.</Text>
                </View>
                <View style={{ marginTop: cond ? 80 : 0, width: cond ? "100%" : "60%", height: cond ? "auto" : "100%", justifyContent: "center", alignItems: "center" }}>
                    <Image source={require("../assets/screen.png")} style={{ height: cond ? 987/1552 * (width - 30) : 400, width: cond ? (width - 30) : 1552/987 * 400 }} />
                </View>
            </View>
        </View>
    )
}