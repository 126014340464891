import { useRef, useState } from "react";
import { Image, Text, TextInput, TouchableOpacity, View, useWindowDimensions, ActivityIndicator, ScrollView } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { logout, signIn, signUp } from "./supabase";
import { getDateFrenchFormat, getName, isDate1GreaterOrEqual, isDateValid } from "./functions"

const ConnectModal = ({ setShowModal }) => {

    const [showSignIn, setShowSignIn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showName, setShowName] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [sex, setSex] = useState(1);

    const passwordRef = useRef(null);
    const lastnameRef = useRef(null);
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);

    const firstCheck = async () => {
        if (!showSignIn) {
            if (email.length <= 4) {
                return alert("Votre email ne peut pas être si court.")
            }

            if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
                return alert("Votre email n'est pas valide.")
            }

            if (password.length < 6) {
                return alert("Votre mot de passe ne peut pas être si court.")
            }

            setShowName(true);
        } else {
            await signIn(email, password)
        }
    }


    const checkDate = (day, month, year) => {

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return alert("Les champs doivent être des nombres.")
        }

        if (day < 1 || day > 31) {
            return alert("Le jour doit être compris entre 1 et 31.");
        }

        if (month < 1 || month > 12) {
            return alert("Le mois doit être compris entre 1 et 12.");
        }

        const now = new Date();
        if (isDate1GreaterOrEqual(year, month, day, now.getFullYear(), now.getMonth() + 1, now.getDate())) {
            return alert("La date doit être dans le passé.");
        }

        if (!isDateValid(year, month, day)) {
            return alert("La date que vous avez entrée n'est pas valide.")
        }

        return true;
    }

    const secondCheck = async () => {
        if (name.length <= 1) {
            return alert("Votre prénom ne peut pas être si court.")
        }

        if (lastname.length <= 1) {
            return alert("Votre nom ne peut pas être si court.")
        }

        if (checkDate(day, month, year)) {
            await signUp(email, password, name, lastname, sex, `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`)
        }
    }

    return (
        <View style={{ minHeight: "100vh", width: "100vw", backgroundColor: "rgba(0, 0, 0, 0.2)", top: 0, left: 0, position: "fixed", zIndex: 2, justifyContent: "center", alignItems: "center" }}>
            <TouchableOpacity activeOpacity={1} onPress={() => setShowModal(false)} style={{ height: "100vh", width: "100vw", left: 0, top: 0, backgroundColor: "rgba(0, 0, 0, 0.2)", position: "fixed" }} />
            <View style={{ backgroundColor: "white", borderRadius: 8, padding: 20 }}>
                <Text style={{ fontSize: 18, fontWeight: "800", textAlign: "center" }}>{showSignIn ? "Connexion" : "Inscription"}</Text>
                <TouchableOpacity onPress={() => setShowModal(false)} style={{ position: "absolute", top: 10, right: 10 }}>
                    <Image style={{ height: 40, width: 40 }} source={require("../assets/close.png")} />
                </TouchableOpacity>
                {!showName && <><Text style={[{ marginTop: 10, fontWeight: "500" }]}>Email</Text>
                    <TextInput style={{
                        borderColor: "lightgray",
                        borderWidth: 1,
                        padding: 10,
                        borderRadius: 8,
                        minWidth: 250
                    }} autoFocus value={email} onChangeText={(e) => setEmail(e)} onSubmitEditing={() => passwordRef.current.focus()} placeholder="Entrez votre email" placeholderTextColor={"lightgray"} />
                    <Text style={[{ marginTop: 10, fontWeight: "500" }]}>Mot de passe</Text>
                    <TextInput ref={passwordRef} style={{
                        borderColor: "lightgray",
                        borderWidth: 1,
                        padding: 10,
                        borderRadius: 8,
                        minWidth: 250
                    }} secureTextEntry value={password} onChangeText={(e) => setPassword(e)} onSubmitEditing={async () => {
                        setLoading(true)
                        await firstCheck()
                        setLoading(false)
                    }} placeholder="Entrez votre mot de passe" placeholderTextColor={"lightgray"} />
                </>
                }
                {
                    !showSignIn && showName &&
                    <>
                        <Text style={[{ marginTop: 10, fontWeight: "500" }]}>Nom</Text>
                        <TextInput style={{
                            borderColor: "lightgray",
                            borderWidth: 1,
                            padding: 10,
                            borderRadius: 8,
                            minWidth: 250
                        }} autoFocus value={name} onChangeText={(e) => setName(e)} onSubmitEditing={() => lastnameRef.current.focus()} placeholder="Entrez votre nom" placeholderTextColor={"lightgray"} />
                        <Text style={[{ marginTop: 10, fontWeight: "500" }]}>Prénom</Text>
                        <TextInput style={{
                            borderColor: "lightgray",
                            borderWidth: 1,
                            padding: 10,
                            borderRadius: 8,
                            minWidth: 250
                        }} ref={lastnameRef} value={lastname} onChangeText={(e) => setLastname(e)} onSubmitEditing={() => dayRef.current.focus()} placeholder="Entrez votre prénom" placeholderTextColor={"lightgray"} />
                        <Text style={[{ marginTop: 10, fontWeight: "500" }]}>Date de naissance</Text>
                        <View style={{ flexDirection: "row" }}>
                            <TextInput ref={dayRef} onSubmitEditing={() => monthRef.current.focus()} style={[{
                                borderColor: "lightgray",
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 8,
                                width: 100
                            }]} placeholderTextColor="gray" placeholder="Jour" value={day} onChangeText={(e) => setDay(e)} />
                            <Text style={[{ marginHorizontal: 5, fontSize: 30, fontWeight: "500" }]}>/</Text>

                            <TextInput ref={monthRef} onSubmitEditing={() => yearRef.current.focus()} style={[{
                                borderColor: "lightgray",
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 8,
                                width: 100
                            }]} placeholderTextColor="gray" placeholder="Mois" value={month} onChangeText={(e) => setMonth(e)} />
                            <Text style={[{ marginHorizontal: 5, fontSize: 30, fontWeight: "500" }]}>/</Text>

                            <TextInput ref={yearRef} style={[{
                                borderColor: "lightgray",
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 8,
                                width: 100
                            }]} placeholderTextColor="gray" placeholder="Année" value={year} onChangeText={(e) => setYear(e)} />
                        </View>
                        <Text style={[{ marginTop: 10, fontWeight: "500" }]}>Sexe</Text>
                        <Picker
                            selectedValue={sex}
                            onValueChange={(e) => setSex(e)}
                            style={{ width: 100 }}
                        >
                            <Picker.Item label="Homme" value={1} />
                            <Picker.Item label="Femme" value={0} />
                            <Picker.Item label="Aucun des deux" value={-1} />
                        </Picker>
                    </>
                }
                <TouchableOpacity style={{
                    marginTop: 20,
                    backgroundColor: "rgb(18,72,251)",
                    padding: 10,
                    borderRadius: 8,
                }} disabled={loading} onPress={async () => {
                    if (!showName) {
                        setLoading(true)
                        await firstCheck()
                        setLoading(false)
                    } else {
                        setLoading(true)
                        await secondCheck()
                        setLoading(false)
                        setShowSignIn(true);
                        setShowName(false);
                        alert("Vous avez reçu un email de confirmation, veuillez cliquez sur le lien.");
                    }
                }}>
                    {loading ?
                        <ActivityIndicator color={"white"} />
                        :
                        <Text style={{ textAlign: "center", color: "white", fontWeight: "600" }}>{showSignIn ? "Se connecter" : showName ? "S'inscrire" : "Suivant"}</Text>
                    }
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setShowSignIn(!showSignIn)} style={{ marginTop: 10 }}>
                    <Text style={{
                        textAlign: "right",
                        color: "gray",
                        fontSize: 12,
                        fontWeight: "500"
                    }} >{showSignIn ? "Pas encore de compte, s'inscrire" : "Déjà un compte, se connecter"}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const RdvModal = ({ setShowModal, user }) => {
    return (
        <View style={{ minHeight: "100vh", width: "100vw", backgroundColor: "rgba(0, 0, 0, 0.2)", top: 0, left: 0, position: "fixed", zIndex: 2, justifyContent: "center", alignItems: "center" }}>
            <TouchableOpacity activeOpacity={1} onPress={() => setShowModal(false)} style={{ height: "100vh", width: "100vw", left: 0, top: 0, backgroundColor: "rgba(0, 0, 0, 0.2)", position: "fixed" }} />
            <View style={{ backgroundColor: "white", minWidth: 300, borderRadius: 8, padding: 20 }}>
                <Text style={{ fontSize: 18, fontWeight: "800", textAlign: "center" }}>Vos rendez-vous</Text>
                <TouchableOpacity onPress={() => setShowModal(false)} style={{ position: "absolute", top: 10, right: 10 }}>
                    <Image style={{ height: 40, width: 40 }} source={require("../assets/close.png")} />
                </TouchableOpacity>
                <ScrollView style={{ maxHeight: 250, marginTop: 20 }}>
                    {
                        user.rdv && user.rdv.map((rdv, i) => {
                            return (
                                <View key={i} style={{ padding: 20, backgroundColor: "rgba(18,72,251, 0.15)", borderRadius: 8 }}>
                                    <Text style={{ fontWeight: "600" }}>Le {getDateFrenchFormat(rdv.date)} de {String(rdv.from).slice(0, 5).replace(":", "h")} à {String(rdv.to).slice(0, 5).replace(":", "h")}</Text>
                                    <Text style={{ marginVertical: 5 }}>Avec {getName(rdv.doctor.name, rdv.doctor.lastname)}</Text>
                                    <Text>{rdv.doctor.address}, {rdv.doctor.city}</Text>
                                </View>
                            )
                        })
                    }
                </ScrollView>
            </View>
        </View>
    )
}

export const TopBar = ({ user }) => {

    const { width } = useWindowDimensions();
    const cond = width < 1200;
    const [showModal, setShowModal] = useState(false);
    const [showRdvModal, setShowRdvModal] = useState(false);

    return (
        <View style={{ width: "100vw", justifyContent: "end", padding: 20, flexDirection: "row", zIndex: 1, alignItems: "center", position: "absolute", top: 0 }}>
            {
                showModal && <ConnectModal setShowModal={setShowModal} />
            }
            {
                showRdvModal && <RdvModal setShowModal={setShowRdvModal} user={user} />
            }
            {
                !cond && !user &&
                <TouchableOpacity onPress={() => window.open("https://app-medalink.pages.dev", "_blank")}>
                    <Text style={{ color: "rgb(18, 72, 251)", fontWeight: "600" }}>Web App praticien</Text>
                </TouchableOpacity>
            }
            {/* {
                user && user.uuid ? <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity style={{ paddingVertical: 8, paddingHorizontal: 14, marginLeft: 10, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }}>
                        <Text style={{ color: "white", fontWeight: "600" }}>{user.name}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <Text style={{ color: "rgb(18, 72, 251)", fontWeight: "600", marginHorizontal: 20 }}>Voir ses rdvs</Text>
                    </TouchableOpacity>
                    
                        <TouchableOpacity onPress={() => logout()}>
                            <Text style={{ color: "rgb(18, 72, 251)", fontWeight: "600" }}>Se deconnecter</Text>
                        </TouchableOpacity>
                    
                </View> :
                    <TouchableOpacity onPress={() => setShowModal(true)} style={{ paddingVertical: 8, paddingHorizontal: 14, marginLeft: 10, backgroundColor: "rgb(18,72,251)", borderRadius: 8 }}>
                        <Text style={{ color: "white", fontWeight: "600" }}>Connexion</Text>
                    </TouchableOpacity>
            } */}
        </View>
    )
}