import { Text, View, useWindowDimensions } from "react-native"

const Card = ({title, content, cond}) => {
    return (
        <View style={{ marginBottom: cond ? 20 : 0, width: cond ? "calc(100vw - 30px - 40px)" : "calc((100vw - 80px - 120px) / 3)", backgroundColor: "rgba(139,18,251, 0.15)", padding: 20, borderRadius: 8}}>
            <Text style={{ fontWeight: "600", marginBottom: 15, fontSize: 16 }}>{title}</Text>
            <Text style={{ textAlign: "justify" }}>{content}</Text>
        </View>
    )
}

const CardBis = ({title, content, cond}) => {
    return (
        <View style={{ marginBottom: cond ? 20 : 0, width: cond ? "calc(100vw - 30px - 40px)" : "calc((100vw - 80px - 120px) / 3)", backgroundColor: "rgba(18,72,251, 0.15)", padding: 20, borderRadius: 8}}>
            <Text style={{ fontWeight: "600", marginBottom: 15, fontSize: 16 }}>{title}</Text>
            <Text style={{ textAlign: "justify" }}>{content}</Text>
        </View>
    )
}

export const Praticiens = () => {

    const { width } = useWindowDimensions();
    const cond = width <  1200;

    const cards = [
        {
            title: "Simplicité et gain de temps",
            content : "Les praticiens peuvent facilement gérer leur emploi du temps, et leurs fiches clients sans tracas, ce qui leur fait gagner du temps et rend la gestion de leurs rendez-vous plus fluide."
        },
        {
            title: "Meilleure gestion de la disponibilité",
            content: "Pour les praticiens, l'affichage des disponibilités par ordre de priorité permet d'optimiser leur charge de travail et d'attirer plus de clients lorsqu'ils ont des créneaux libres, tout en évitant d'être surchargés lorsqu'ils sont complets."
        },
        {
            title: "Visibilité et crédibilité accrues",
            content: "Notre politique stricte de certification des praticiens, vérifiée par une intelligence artificielle et une équipe humaine, permet aux praticiens de gagner en visibilité et en crédibilité auprès des patients potentiels."
        }
    ]

    const cardsBis = [
        {
            title: "Aucune installation requise",
            content: "Avec Medalink, profitez d'un logiciel en ligne qui ne nécessite aucune installation sur votre ordinateur. Accédez à votre solution partout et à tout moment, simplement depuis n'importe quelle connexion Internet."
        },
        {
            title: "Toujours à la pointe de l'inovation",
            content: "Grâce aux mises à jour intégrées, Medalink évolue constamment avec de nouvelles fonctionnalités et améliorations. Vous n'avez plus à vous soucier des mises à jour, vous bénéficiez automatiquement des dernières avancées."
        },
        {
            title: "Sécurité de haut niveau pour votre activité",
            content: "Medalink s'engage à assurer une sécurité complète pour vos données de santé. Des connexions chiffrées garantissent l'intégrité, la sécurité et la confidentialité des dossiers transmis vers nos serveurs, vous offrant une tranquillité d'esprit optimale."
        }
    ]

    return (
        <>
            <Text style={{ marginLeft: 40, marginTop: 40, fontSize: 32, fontWeight: "800", color: "rgb(139,18,251)", marginBottom: 20 }}>Pour les praticiens</Text>
            <View style={{ flexDirection:  cond ? "column" : "row", paddingHorizontal: cond ? 0 : 40, justifyContent: "space-between", alignItems: "center" }}>
                {
                    cards.map((card, index) => {
                        return <Card title={card.title} content={card.content} cond={cond} key={index} />
                    })
                }
            </View>
            <Text style={{ marginLeft: 40, marginTop: 40, fontSize: 32, fontWeight: "800", color: "rgb(18,72,251)", marginBottom: 20 }}>La plateforme</Text>
            <View style={{ flexDirection:  cond ? "column" : "row", paddingHorizontal: cond ? 0 : 40, justifyContent: "space-between", alignItems: "center", marginBottom: 30 }}>
                {
                    cardsBis.map((card, index) => {
                        return <CardBis title={card.title} content={card.content} cond={cond} key={index} />
                    })
                }
            </View>
        </>    
    )
}