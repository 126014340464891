import { Text, View, useWindowDimensions } from "react-native"

const Card = ({title, content, cond}) => {
    return (
        <View style={{ marginBottom: cond ? 20 : 0, width: cond ? "calc(100vw - 30px - 40px)" : "calc((100vw - 80px - 120px) / 3)", backgroundColor: "rgba(251,147,18, 0.15)", padding: 20, borderRadius: 8}}>
            <Text style={{ fontWeight: "600", marginBottom: 15, fontSize: 16 }}>{title}</Text>
            <Text style={{ textAlign: "justify" }}>{content}</Text>
        </View>
    )
}

export const Clients = () => {

    const { width } = useWindowDimensions();
    const cond = width <  1200;

    const cards = [
        {
            title: "Orientation personnalisée",
            content : "Grâce au chatbot intelligent, les patients sont dirigés vers le praticien le plus adapté à leurs besoins, ce qui leur permet d'obtenir des soins ciblés et appropriés dès le premier rendez-vous."
        },
        {
            title: "Communication claire, soins sur mesure",
            content: "Les patients ont la possibilité de fournir un résumé de leur conversation avec l'intelligence artificielle avant chaque rendez-vous, ce qui permet aux praticiens de mieux comprendre leurs besoins avant même la consultation, favorisant ainsi une prise en charge plus efficace."
        },
        {
            title: "Confiance dans le choix des praticiens",
            content: "La certification stricte des praticiens, vérifiée par nos soins, inspire confiance aux utilisateurs quant à la qualité et à la crédibilité des professionnels disponibles sur la plateforme. Nous verifions les diplomes de chacun de nos praticiens."
        }
    ]

    return (
        <>
            <Text style={{ marginLeft: 40, marginTop: 40, fontSize: 32, fontWeight: "800", color: "rgb(251,147,18)", marginBottom: 20 }}>Pour les patients</Text>
            <View style={{ flexDirection:  cond ? "column" : "row", paddingHorizontal: cond ? 0 : 40, justifyContent: "space-between", alignItems: "center" }}>
                {
                    cards.map((card, index) => {
                        return <Card title={card.title} content={card.content} cond={cond} key={index} />
                    })
                }
            </View>
        </>    
    )
}