import { useEffect, useState } from "react";
import { Clients } from "./src/Clients";
import { Home } from "./src/Home";
import { Praticiens } from "./src/Praticien";
import { TopBar } from "./src/TopBar";
import { View } from "react-native";
import { fetchClient, listenAuth, supabase } from "./src/supabase";

const App = () => {

  const [session, setSession] = useState({});
  const [user, setUser] = useState({})

  const getSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    const { session } = data;

    console.log(session)

    setSession(session);
  }

  const checkUser = async () => {
    if (session && session.user) {
      const userData = await fetchClient(session.user.id);
      console.log(userData)
      if (userData) {
        setUser(userData[0])
      } else {
        setUser({})
      }
    }
  }

  useEffect(() => {
    getSession();
    listenAuth(setSession);
  }, []);

  useEffect(() => {
    checkUser()    
  }, [session])

  return (
    <View>
      <TopBar user={user} />
      <Home />
      <Clients />
      <Praticiens />
    </View>
  )
}

export default App;