import { createClient } from "@supabase/supabase-js";

export const supabase = createClient("https://fnontbrkxrkzgzdiqtkb.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZub250YnJreHJremd6ZGlxdGtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc5NTI2MDQsImV4cCI6MjAwMzUyODYwNH0.e_PZuSCVqi1EKXXNt-zT94Ie37Hz8lEs6wZQxeP_YUU")

export const listenAuth = (setSession) => {
    supabase.auth.onAuthStateChange((event, session) => {
        setSession(session)
    })
}

export const signIn = async (email, pwd) => {
    const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: pwd
    });

    if (error) {
        if (String(error).includes("Email not confirmed")) {
            alert("Veuillez confirmer votre adresse email avant de vous connecter, un email de confirmation vous a été envoyé.");
            return error;
        }
        alert("Une erreur est survenue lors de la connexion.")
        return error;
    }
}

export const signUp = async (email, pwd, name, lastname, sex, birthdate) => {
    const { data, error } = await supabase.auth.signUp({
        email: email,
        password: pwd,
        options: {
            data: {
                lastname: name,
                name: lastname,
                sex: sex,
                birthdate: birthdate,                
            }
        }
    })

    if (error) {
        alert("Une erreur est survenue lors de l'inscription")
        return error;
    }

    return data;
}

export const fetchClient = async (uuid) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    // const { data, error } = await supabase.from("doctors").select("*, tasks(*), availability(abs_date, agenda), rdv:decrypted_rdv(id, created_at, user_id, date, from, to, reason:decrypted_reason, client:clients(*)))").eq("uuid", uuid).gte("decrypted_rdv.date", formattedDate).gte("tasks.date", formattedDate);
    const { data, error } = await supabase.from("clients").select("*, rdv:decrypted_rdv(id, created_at, doctor_id, date, from, to, reason:decrypted_reason, doctor:doctors(*))").eq("uuid", uuid).gte("decrypted_rdv.date", formattedDate);

    if (error) {
        console.log("err", error);
        return;
    }

    if (data.length == 1) {              
        return data;
    }

}

export const logout = async () => {
    return await supabase.auth.signOut();
}