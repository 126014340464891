export const isDate1GreaterOrEqual = (year1, month1, day1, year2, month2, day2) => {

    if (year1 > year2) {
        return true;
    } else if (year1 == year2 && month1 > month2) {
        return true;
    } else if (year1 == year2 && month1 == month2 && day1 >= day2) {
        return true;
    } else {
        return false;
    }

}

export const isDateValid = (year, month, day) => {
    const date = new Date(year, month - 1, day);

    return (date.getFullYear() == year && date.getMonth() + 1 == month && date.getDate() == day);
}

export const getDateFrenchFormat = (date) => {
    return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`
}

export const getName = (name, lastname) => {
    return `${String(lastname).toUpperCase()} ${String(name).at(0).toUpperCase() + String(name).slice(1).toLowerCase()}`
}